// extracted by mini-css-extract-plugin
export var approachInfoRoundBlock__between = "cT_wk";
export var approachInfoRoundBlock__card = "cT_wj";
export var approachInfoRoundBlock__contentFive = "cT_wv";
export var approachInfoRoundBlock__contentFour = "cT_wt";
export var approachInfoRoundBlock__contentOne = "cT_wq";
export var approachInfoRoundBlock__contentSix = "cT_ww";
export var approachInfoRoundBlock__contentThree = "cT_ws";
export var approachInfoRoundBlock__contentTwo = "cT_wr";
export var approachInfoRoundBlock__description = "cT_wp";
export var approachInfoRoundBlock__icon = "cT_wm";
export var approachInfoRoundBlock__imageDesktop = "cT_wl";
export var approachInfoRoundBlock__title = "cT_wn";