// extracted by mini-css-extract-plugin
export var agileApproachSection = "l_X";
export var agileApproachSection__testimonial = "l_Y";
export var bestPracticesApproachSection = "l_Z";
export var caseApproachSection = "l_6";
export var processApproachSection = "l_3";
export var projectSuccessApproachSection = "l_0";
export var recentArticlesApproachSection = "l_5";
export var successStoriesApproachSection = "l_7";
export var techStackApproachSection = "l_4";
export var testimonialApproachSection = "l_1";
export var toolslApproachSection = "l_2";