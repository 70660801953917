// extracted by mini-css-extract-plugin
export var discoverWorksItem = "cN_vp";
export var discoverWorksItem__arrowLink = "cN_vC";
export var discoverWorksItem__bg = "cN_vs";
export var discoverWorksItem__clientImg = "cN_vG";
export var discoverWorksItem__clientName = "cN_vH";
export var discoverWorksItem__clientPosition = "cN_vJ";
export var discoverWorksItem__gradientAfricar = "cN_vX";
export var discoverWorksItem__gradientBabel = "cN_v0";
export var discoverWorksItem__gradientCaker = "cN_vQ";
export var discoverWorksItem__gradientDv = "cN_vW";
export var discoverWorksItem__gradientImpact = "cN_vL";
export var discoverWorksItem__gradientIx = "cN_vV";
export var discoverWorksItem__gradientMalleni = "cN_vM";
export var discoverWorksItem__gradientPmk = "cN_vS";
export var discoverWorksItem__gradientPmkDesktop = "cN_vT";
export var discoverWorksItem__gradientRew = "cN_vK";
export var discoverWorksItem__gradientTimebot = "cN_vZ";
export var discoverWorksItem__gradientTradeaboat = "cN_vN";
export var discoverWorksItem__gradientTradervs = "cN_vP";
export var discoverWorksItem__gradientVaria = "cN_vY";
export var discoverWorksItem__gradientWowner = "cN_vR";
export var discoverWorksItem__header = "cN_vq";
export var discoverWorksItem__imageHover = "cN_vr";
export var discoverWorksItem__mobileImage = "cN_vD";
export var discoverWorksItem__results = "cN_vw";
export var discoverWorksItem__results_head = "cN_vx";
export var discoverWorksItem__results_num = "cN_vy";
export var discoverWorksItem__results_text = "cN_vz";
export var discoverWorksItem__results_textOnly = "cN_vB";
export var discoverWorksItem__review = "cN_vF";
export var discoverWorksItem__title = "cN_vt";
export var discoverWorksItem__wrapper = "cN_vv";