// extracted by mini-css-extract-plugin
export var servicesTechStack__item = "f6_PT";
export var servicesTechStack__itemWrapper = "f6_PS";
export var servicesTechStack__name = "f6_PV";
export var servicesTechStack__name_chromedevtools = "f6_P2";
export var servicesTechStack__name_digitalocean = "f6_P0";
export var servicesTechStack__name_elasticsearch = "f6_PY";
export var servicesTechStack__name_gitlab = "f6_P1";
export var servicesTechStack__name_googleCloudPlatform = "f6_P4";
export var servicesTechStack__name_googleDocs = "f6_P3";
export var servicesTechStack__name_googleanalytics = "f6_PW";
export var servicesTechStack__name_prometheus = "f6_PX";
export var servicesTechStack__name_ror = "f6_PZ";